@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@import "react-toastify/dist/ReactToastify.css";
@import "react-tooltip/dist/react-tooltip.css";
@import "react-loading-skeleton/dist/skeleton.css";

// Defined colors

$color-primary: rgb(214, 153, 175);
$color-secondary: rgb(255, 212, 190);
$color-p-white: rgb(255, 255, 255);
$color-p-black: rgb(0, 0, 0);
$color-warning: rgb(195, 105, 0);
$color-danger: rgb(164, 48, 63);
$color-success: rgb(35, 240, 199);
$color-disabled: rgb(240, 240, 240);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Function to compute RGB color from CSS variable
@function rgb-var($variable-name) {
  @return rgb(var(#{$variable-name}));
}

// Mixin to generate lighter and lightest colors
@mixin generate-shades(
  $name,
  $base-color,
  $lighter-amount: 15%,
  $lightest-amount: 30%
) {
  --#{$name}: #{red($base-color)}, #{green($base-color)}, #{blue($base-color)};
  --#{$name}-lighter: #{red(lighten($base-color, $lighter-amount))},
    #{green(lighten($base-color, $lighter-amount))},
    #{blue(lighten($base-color, $lighter-amount))};
  --#{$name}-lightest: #{red(lighten($base-color, $lightest-amount))},
    #{green(lighten($base-color, $lightest-amount))},
    #{blue(lighten($base-color, $lightest-amount))};
}

// This is the authoritative source for all the colors used in the app.
// They are referenced in tailwind.config.js, the React tooltip declarations
// below, and anywhere else that colors are defined.
//
// https://tailwindcss.com/docs/customizing-colors#using-css-variables

@layer base {
  :root {
    // Computed shades

    @include generate-shades(color-primary, $color-primary, 10, 20);
    @include generate-shades(color-secondary, $color-secondary, 3, 7);
    @include generate-shades(color-p-white, $color-p-white);
    @include generate-shades(color-p-black, $color-p-black, 15, 50);
    @include generate-shades(color-warning, $color-warning);
    @include generate-shades(color-danger, $color-danger);
    @include generate-shades(color-success, $color-success);
    --color-disabled: #{$color-disabled};

    // https://github.com/fkhadra/react-toastify/issues/374

    --toastify-icon-color-success: #{$color-success};
    --toastify-color-success: #{$color-success};
    --toastify-icon-color-error: #{$color-danger};
    --toastify-color-error: #{$color-danger};
    --toastify-icon-color-warning: #{$color-warning};
    --toastify-color-warning: #{$color-warning};
    --toastify-icon-color-info: #{$color-primary};
    --toastify-color-info: #{$color-primary};
  }

  html {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
}

// https://react-tooltip.com/docs/getting-started

:root {
  --rt-color-white: rgb(var(--color-p-white));
  --rt-color-dark: rgb(var(--color-p-black));
  --rt-color-success: rgb(var(--color-success));
  --rt-color-error: rgb(var(--color-danger));
  --rt-color-warning: rgb(var(--color-warning));
  --rt-color-info: rgb(var(--color-primary));
  --rt-opacity: 1;
}

// https://github.com/ReactTooltip/react-tooltip/issues/277

.react-tooltip__show {
  opacity: 1 !important;
}
